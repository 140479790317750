import * as React from "react";

type SpinnerType = {
  strokeWidth: number;
  className: string;
};

export default function Spinner({ strokeWidth, className }: SpinnerType) {
  return (
    <div className={className}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
}
